import { ReactNode, ReactElement, forwardRef } from 'react';
import { encodeSpacesInUrl } from 'utils/url';
import { isIndexed } from 'routes';
import RouteLink from './RouteLink';
import NavigationLink from './NavigationLink';
import { createRouteData } from './helpers';
import { HTMLAnchorFactory, SystemLinkTo, LinkTo } from './types';

export type Props = Omit<Parameters<typeof NavigationLink>[0], 'createLink'> & {
  children?: ReactNode;
  rel?: string;
  target?: string;
  title?: string | null;
} & Omit<React.HTMLProps<HTMLAnchorElement>, 'rel' | 'target' | 'onClick' | 'title'>

type EmptyLinkProps = Omit<Props, 'to' | 'url'> & {
  ref?: React.Ref<HTMLAnchorElement>;
}

export type SystemLinkProps = EmptyLinkProps & {
  to: SystemLinkTo;
  url?: string;
}

export type LinkProps = EmptyLinkProps & {
  to?: LinkTo;
  url: string;
}

declare function Link(props: LinkProps): ReactElement;
declare function Link(props: SystemLinkProps): ReactElement;

export default forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    to,
    url,
    omitScroll,
    replaceHistory,
    options,
    onClick,
    children,
    rel,
    target,
    title,
    ...attributes
  } = props;

  const createLink: HTMLAnchorFactory = (href, onLinkClick) => {
    let relValue = rel;
    if (relValue === undefined && to) {
      if (isIndexed(createRouteData(to).routeName))
        relValue = 'nofollow';
    }

    return (
      <a
        href={href ? encodeSpacesInUrl(href) : undefined}
        data-broken={href ? null : 'true'}
        onClick={onLinkClick}
        ref={ref}
        rel={relValue}
        target={target}
        title={title || undefined}
        {...attributes}
      >
        {children}
      </a>
    );
  };

  if (!url && to)
    return (
      <RouteLink
        to={to as SystemLinkTo}
        omitScroll={omitScroll}
        replaceHistory={replaceHistory}
        options={options}
        onClick={onClick}
        createLink={createLink}
        disabled={attributes.disabled}
      />
    );

  return (
    <NavigationLink
      url={url}
      to={to}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      onClick={onClick}
      createLink={createLink}
      disabled={attributes.disabled}
    />
  );
}) as typeof Link;
