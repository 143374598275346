import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesBuilder } from 'routes';
import Paging from 'components/primitives/paging';
import { navigateTo } from 'behavior/events';
import { modifyBasket } from 'behavior/basket';
import { addPageParamToUrl } from 'behavior/pages';
import { useUpdateContext } from './updateContext';

const BasketPaging = ({ page, totalCount, pageUrl, navigateTo, className }) => {
  const { quantities, reset, isB2BPreset } = useUpdateContext();

  const handleSelect = useCallback(index => {
    navigateTo(createRouteDataForPage(index, quantities, isB2BPreset), addPageParamToUrl(pageUrl, index + 1), { replaceHistory: true });
    requestAnimationFrame(() => reset());
  }, [pageUrl, quantities, isB2BPreset]);
  const getUrl = useCallback(index => addPageParamToUrl(pageUrl, index + 1), [pageUrl]);

  return (
    <Paging
      pageIndex={page.index}
      pageSize={page.size}
      totalCount={totalCount}
      onSelect={handleSelect}
      scrollToId="basketPagingScrollTo"
      getUrl={getUrl}
      className={className}
    />
  );
};

BasketPaging.propTypes = {
  page: PropTypes.shape({
    size: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
  }),
  totalCount: PropTypes.number,
  pageUrl: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default connect(({ routing }) => ({ pageUrl: routing.location.pathname }), { navigateTo })(BasketPaging);

function createRouteDataForPage(pageIndex, changedLines, isB2BPreset) {
  const routeData = routesBuilder.forBasket(pageIndex);
  if (!routeData.options)
    routeData.options = {};

  addModifyBasketAction(routeData, pageIndex, changedLines, isB2BPreset);

  routeData.options.linesOnly = true;
  return routeData;
}

function addModifyBasketAction(routeData, pageIndex, changedLines, isB2BPreset) {
  if (!changedLines || !changedLines.size)
    return;

  const modified = [];
  for (const [id, { quantity }] of changedLines) {
    if (!quantity && quantity !== 0)
      return; // Not valid number, can't submit form.

    modified.push({ id, quantity });
  }

  routeData.options.action = modifyBasket(modified, null, !isB2BPreset, pageIndex);
}