import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { sanitizeId } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { SimpleText } from 'components/sanaText';
import Stock from './Stock';
import Specifications from './Specifications';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import WithProductRoute from './WithProductRoute';

const Description = ({ id, url, title, externalItemNumber, showStock }) => {
  const isPreview = useIsPreview();

  return (
    <div className={styles.productDescription}>
      {isPreview && !url
        ? <Placeholder className={styles.titlePreviewPlaceholder} />
        : (
          <WithProductClickTracking>
            {handleClick => (
              <WithProductRoute product={{ id, url }}>
                {(productUrl, productRouteBuilder) => (
                  <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} className={styles.productTitle}>
                    <h2 id={sanitizeId('title' + id)}>
                      {title}
                      {/*Use dot for screen readers to add pause after the title.*/}
                      <i className="visually-hidden">.</i>
                    </h2>
                  </Link>
                )}
              </WithProductRoute>
            )}
          </WithProductClickTracking>
        )
      }
      <div className={styles.productIdStock}>
        <span className={styles.productId}>
          <span className={styles.productIdName}>
            <SimpleText
              textKey="General_Product_Id"
              placeholder={<Placeholder className={styles.productIdNamePlaceholder} />}
            />
          </span>
          <span className={styles.productIdValue}>{id}</span>
          {' '}
        </span>
        {showStock && <Stock />}
      </div>
      {externalItemNumber && (
        <div>
          <SimpleText textKey="ExternalItemNumber" formatWith={[externalItemNumber]} />
        </div>
      )}
      <Specifications />
    </div>
  );
};

Description.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  externalItemNumber: PropTypes.string,
  showStock: PropTypes.bool,
};

export default connectToContext([ProductContext],
  ({ id, url, title, externalItemNumber }) => ({ id, url, title, externalItemNumber }),
)(
  memo(Description),
);