import styles from '../../Order.module.scss';
import PropTypes from 'prop-types';
import { toLocaleDate, formatAsPercentage } from 'utils/format';
import UnitOfMeasure from '../UnitOfMeasure';
import ShippingStatus from '../ShippingStatus';
import QuoteLineStock from '../../quote/QuoteLineStock';
import { useCultureName } from 'utils/hooks';
import { FormattedPrice } from 'components/primitives/price';
import { SalesAgreementTerm, SalesAgreementId } from '../SalesAgreements';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';

const SimpleLine = ({
  line: {
    productId,
    title,
    shippingStatus,
    shipmentDate,
    price,
    subtotal,
    discountPercentage,
    uom,
    quantity,
    quantityShipped,
    quantityInvoiced,
    quantityOutstanding,
    inStock,
    salesAgreementId: salesAgreementIdPerLine,
    salesAgreementLineId,
    externalItemNumber
  },
  showId = false,
  options: {
    isB2CCustomer,
    showUOM,
    showStock,
    showPrices,
    showDiscounts,
    showExtraColumns,
    showShippingStatus,
    showExternalItemNumbers,
    currencyInfo,
  },
  lineTexts,
  className = null,
  cancelled,
  agreementId,
}) => {
  const [canUseSalesAgreements] = useHasAbilities(AbilityTo.UseSalesAgreements);
  const cultureName = useCultureName();
  const valueClassName = `${styles.value} ${cancelled ? styles.cancelled : ''}`;
  const uomId = uom && uom.id;
  const uomDescription = uom && uom.description;

  return (
    <>
      {cancelled &&
        <tr className="visually-hidden">
          <td>
            <span>{lineTexts.lineStatus}</span>
            <span>{lineTexts.lineStatusCancelled}</span>
          </td>
        </tr>
      }
      <tr className={className}>
        {showId &&
          <td>
            <span className={styles.name}>{lineTexts.id}</span>
            <span className={valueClassName}>{productId}</span>
          </td>
        }
        <td>
          <span className={styles.name}>{lineTexts.title}</span>
          <span className={valueClassName}>
            {title}
            {canUseSalesAgreements && salesAgreementIdPerLine
              && <SalesAgreementId agreementId={salesAgreementIdPerLine} />
            }
            {canUseSalesAgreements && salesAgreementLineId
              && <SalesAgreementTerm salesAgreementLineId={salesAgreementLineId} agreementId={agreementId ?? salesAgreementIdPerLine} />
            }
          </span>
        </td>
        {showExternalItemNumbers &&
          <td>
           <span className={styles.name}>
              <span title={lineTexts.externalItemNumber}>{lineTexts.externalItemNumber}</span>
            </span>
            <span className={valueClassName}>{externalItemNumber}</span>
          </td>
        }
        {showShippingStatus &&
          <td>
            <span className={styles.name}>{lineTexts.lineShippingStatus}</span>
            <span className={valueClassName}><ShippingStatus status={shippingStatus} /></span>
          </td>
        }
        {!isB2CCustomer &&
          <td>
            <span className={styles.name}>
              <span title={lineTexts.shipmentDateTitle}>{lineTexts.shipmentDate}</span>
            </span>
            <span className={valueClassName}>{toLocaleDate(shipmentDate, cultureName)}</span>
          </td>
        }
        {showPrices &&
          <td className={styles.priceCell}>
            <span className={styles.name}>{lineTexts.price}</span>
            <span className={valueClassName}>
              <FormattedPrice price={price} currencyInfo={currencyInfo} />
            </span>
          </td>
        }
        {showDiscounts &&
          <td>
            <span className={styles.name}>{lineTexts.discount}</span>
            <span className={valueClassName}>
              {discountPercentage
                ? formatAsPercentage(discountPercentage, currencyInfo, true /* include sign*/)
                : ''
              }
            </span>
          </td>
        }
        <td>
          <span className={styles.name}>
            {isB2CCustomer ? lineTexts.quantity : <span title={lineTexts.quantity}>{lineTexts.qty}</span>}
          </span>
          <span className={valueClassName}>{quantity}</span>
        </td>
        {showUOM &&
          <td>
            <span className={styles.name}>
              <span title={lineTexts.unitOfMeasure}>{lineTexts.uom}</span>
            </span>
            <span className={valueClassName}><UnitOfMeasure id={uomId} description={uomDescription} /></span>
          </td>
        }
        {showStock &&
          <td>
            <span className={styles.name}>{lineTexts.stock}</span>
            <span className={valueClassName}>
              <QuoteLineStock inStock={inStock} lineTexts={lineTexts} />
            </span>
          </td>
        }
        {showExtraColumns &&
          <td>
            <span className={styles.name}>
              <span title={lineTexts.quantityShipped}> {lineTexts.linesShippedQuantity}</span>
            </span>
            <span className={valueClassName}>{quantityShipped}</span>
          </td>
        }
        {showExtraColumns &&
          <td>
            <span className={styles.name}>
              <span title={lineTexts.quantityInvoiced}>
                {lineTexts.quantityInvoicedTitle}
              </span>
            </span>
            <span className={valueClassName}>{quantityInvoiced}</span>
          </td>
        }
        {!isB2CCustomer &&
          <td>
            <span className={styles.name}>
              <span title={lineTexts.outstandingQuantityAlt}>
                {lineTexts.outstandingQuantity}
              </span>
            </span>
            <span className={valueClassName}>{quantityOutstanding}</span>
          </td>
        }
        {showPrices &&
          <td className={styles.totalCell}>
            <span className={styles.name}>{lineTexts.totals}</span>
            <span className={valueClassName}>
              <FormattedPrice price={subtotal} currencyInfo={currencyInfo} />
            </span>
          </td>
        }
      </tr>
    </>
  );
};

SimpleLine.propTypes = {
  line: PropTypes.shape({
    productId: PropTypes.string,
    title: PropTypes.string,
    shippingStatus: PropTypes.string,
    shipmentDate: PropTypes.string,
    price: PropTypes.number,
    subtotal: PropTypes.number,
    discountPercentage: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number,
    quantityShipped: PropTypes.number,
    quantityInvoiced: PropTypes.number,
    quantityOutstanding: PropTypes.number,
    inStock: PropTypes.bool,
    salesAgreementId: PropTypes.string,
    salesAgreementLineId: PropTypes.string,
    externalItemNumber: PropTypes.string,
  }),
  showId: PropTypes.bool,
  options: PropTypes.shape({
    isB2CCustomer: PropTypes.bool,
    showUOM: PropTypes.bool,
    showStock: PropTypes.bool,
    showPrices: PropTypes.bool,
    showDiscounts: PropTypes.bool,
    showExtraColumns: PropTypes.bool,
    showShippingStatus: PropTypes.bool,
    showExternalItemNumbers: PropTypes.bool,
    currencyInfo: PropTypes.shape({
      symbol: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  lineTexts: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    lineShippingStatus: PropTypes.string,
    shipmentDateTitle: PropTypes.string,
    shipmentDate: PropTypes.string,
    price: PropTypes.string,
    discount: PropTypes.string,
    quantity: PropTypes.string,
    qty: PropTypes.string,
    unitOfMeasure: PropTypes.string,
    uom: PropTypes.string,
    quantityShipped: PropTypes.string,
    linesShippedQuantity: PropTypes.string,
    quantityInvoiced: PropTypes.string,
    quantityInvoicedTitle: PropTypes.string,
    outstandingQuantityAlt: PropTypes.string,
    outstandingQuantity: PropTypes.string,
    totals: PropTypes.string,
    stock: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  cancelled: PropTypes.bool,
  agreementId: PropTypes.string,
};

export default SimpleLine;
