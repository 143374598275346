import styles from '../../Checkout.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, HiddenSubmitButton } from 'components/primitives/form';
import { makeRichText, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { MethodCost } from '../MethodCost';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const PaymentMethodsList = ({
  paymentMethods,
  selectedMethodId,
  onSelect,
  onSubmit,
  currency,
  validationMsg,
}) => {
  const [description, costsLbl, freeLbl] = useSanaTexts(['PleaseSelectYourPaymentMethod', 'Costs', 'FreeCosts'], makeSimpleText).texts;
  const [selectedMethodIdState, setSelectedMethodIdState] = useState(selectedMethodId);
  const isPreview = useIsPreview();

  useEffect(() => {
    setSelectedMethodIdState(selectedMethodId);
  }, [paymentMethods, selectedMethodId]);

  const handleChange = e => {
    setSelectedMethodIdState(e.target.value);
    onSelect && onSelect(e.target.value);
  };

  let hasIcons;

  const methodNodes = paymentMethods.map((method, index) => {
    const costId = method.cost != null ? 'pmnt-cost-' + index : '',
      descriptionId = method.description ? 'pmnt-descr-' + index : '';

    if (method.imageUrl)
      hasIcons = true;

    const describedBy = (costId + ' ' + descriptionId).trim() || null;

    return (
      <div key={index}>
        <div className={styles.item}>
          <Radio value={method.id}
            name="payment"
            aria-describedby={describedBy}
            checked={selectedMethodIdState === method.id}
            onChange={handleChange}
            className={styles.radio}
          >
            <i className={styles.icon} aria-hidden>
              {method.imageUrl ? <img src={method.imageUrl} alt={method.name} /> : null}
            </i>
            {isPreview
              ? <Placeholder className={styles.radioPreviewPlaceholder}>&nbsp;</Placeholder>
              : method.name
            }
          </Radio>
          <MethodCost cost={method.cost} id={costId} ariaLabel={costsLbl} freeCostText={freeLbl} currency={currency} />
        </div>
        {method.description &&
          <div className={styles.description} id={descriptionId}>
            {makeRichText(method.description)}
          </div>
        }
      </div>
    );
  });

  const Tag = onSubmit ? 'form' : 'div';

  return (
    <>
      {description && <div className={styles.description}>{makeRichText(description)}</div>}
      <Tag className={styles.methodsList + (hasIcons ? '' : ' ' + styles.noIcons)} onSubmit={onSubmit}>
        {methodNodes}
        {onSubmit && <HiddenSubmitButton />}
      </Tag>
    </>
  );
};

PaymentMethodsList.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    cost: PropTypes.number,
  }).isRequired).isRequired,
  selectedMethodId: PropTypes.string,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func,
  currency: PropTypes.shape({
    id: PropTypes.string.isRequired,
    symbol: PropTypes.string,
  }).isRequired,
  validationMsg: PropTypes.node,
};

export default PaymentMethodsList;