import type { AccountInfo } from '@azure/msal-browser';

const externalAccountKey = 'xtaa';

export const rememberExternalAccount = (account: AccountInfo | null) => {
    account && localStorage.setItem(externalAccountKey, account.homeAccountId);
};

export const getRememberedExternalAccount = () => localStorage.getItem(externalAccountKey);

export const forgetExternalAccount = () => localStorage.removeItem(externalAccountKey);