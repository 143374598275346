exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditProfile_credit-details,.EditProfile_form{padding-top:15px;max-width:46.75em}.EditProfile_credit-details{width:100%}.EditProfile_credit-details td,.EditProfile_credit-details th{border:none;padding-left:0;padding-bottom:15px}.EditProfile_credit-details th{text-align:left;font-weight:400;width:36%;padding-right:3%}.EditProfile_heading{display:flex;flex-wrap:wrap;align-items:center;margin-bottom:15px}.EditProfile_heading .EditProfile_spinner{margin-left:25px}", ""]);

// exports
exports.locals = {
	"credit-details": "EditProfile_credit-details",
	"creditDetails": "EditProfile_credit-details",
	"form": "EditProfile_form",
	"heading": "EditProfile_heading",
	"spinner": "EditProfile_spinner"
};