import styles from '../Checkout.module.scss';
import { useEffect, useContext, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Steps, selectPaymentMethod } from 'behavior/pages/checkout';
import {
  PaymentMethodStep as PaymentMethodStepBase,
  PaymentMethodStepBody,
} from '../base/payment';
import CheckoutContext from './CheckoutContext';
import { useDispatch } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import scrollIntoView from 'scroll-into-view';

const PaymentMethodStep = ({ info, isCompleted }) => {
  const { setLoading, registerStep } = useContext(CheckoutContext);
  const [showValidation, setShowValidation] = useState();
  const methodSelectedRef = useRef(!!info.paymentMethodId);

  useEffect(() => {
    const selectedPaymentMethodAvailable = info.paymentMethods?.some(m => m.id === info.paymentMethodId);
    if (selectedPaymentMethodAvailable)
      setShowValidation(false);

    methodSelectedRef.current = selectedPaymentMethodAvailable;
  }, [info.paymentMethodId, info.paymentMethods]);

  useEffect(() => {
    const validateStep = () => {
      if (methodSelectedRef.current)
        return true;

      setShowValidation(true);
      scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });
      return false;
    };

    registerStep(Steps.Payment, styles.paymentMethod, [Steps.Address, Steps.Payment], validateStep);
  }, []);

  const dispatch = useDispatch();

  const onSelect = useCallback(id => {
    setShowValidation(false);
    methodSelectedRef.current = true;

    dispatch(selectPaymentMethod(id));
    setLoading(Steps.Payment);
  }, [setLoading]);

  const [validationMsg] = useSanaTexts(['PaymentMethods_ValidationMessage']).texts;

  return (
    <PaymentMethodStepBase
      className={styles.paymentMethod}
      isQuote={info.isQuote}
      isCompleted={isCompleted}
    >
      <PaymentMethodStepBody
        totals={info.totals}
        paymentMethods={info.paymentMethods}
        selectedMethodId={info.paymentMethodId}
        recheck={info.recheckPaymentMethod}
        onSelect={onSelect}
        isQuote={info.isQuote}
        validationMsg={showValidation && validationMsg}
      />
    </PaymentMethodStepBase>
  );
};

PaymentMethodStep.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    paymentMethods: PropTypes.array,
    totals: PropTypes.object,
    paymentMethodId: PropTypes.string,
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default PaymentMethodStep;
