import styles from './Header.module.scss';
import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFocusResetOnLocationChange } from 'components/objects/navigation';
import { VerticalSliding } from 'components/primitives/transitions';
import { AccordionNav } from 'components/objects/navigation';
import { LanguageSelector } from 'components/objects/languageSelector';

export const accordionId = 'mobileTopMenu';

const MobileTopMenu = ({ expanded, setExpanded }) => {
  const setCollapsed = useCallback(() => setExpanded(false), []);
  const handleSkipLinkClick = useCallback(() => setExpanded(true), []);

  useFocusResetOnLocationChange(!expanded, setCollapsed);

  return (
    <VerticalSliding
      id={accordionId}
      expanded={expanded}
      containerClass={styles.topMenuContent}
    >
      <LanguageSelector isMobile />
      <AccordionNav isExpanded={expanded} handleSkipLinkClick={handleSkipLinkClick} />
    </VerticalSliding>
  );
};

MobileTopMenu.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileTopMenu);
