import { filterExistingImages } from 'utils/helpers';

export const QUICK_ORDER_ADD_PRODUCTS = 'QUICK_ORDER_ADD_PRODUCTS';
export const quickOrderAddProducts = (added, modified, size, calculated = false) => ({
  type: QUICK_ORDER_ADD_PRODUCTS,
  payload: {
    added,
    modified,
    size,
    calculated,
  },
});

export const QUICK_ORDER_LINES_RECEIVED = 'QUICK_ORDER_LINES_RECEIVED';
export const quickOrderLinesReceived = lines => ({
  type: QUICK_ORDER_LINES_RECEIVED,
  payload: { lines: filterImages(lines) },
});

export const QUICK_ORDER_CLEARED = 'QUICK_ORDER_CLEARED';
export const clearQuickOrder = () => ({ type: QUICK_ORDER_CLEARED });

function filterImages(lines) {
  if (lines && lines.list)
    for (const line of lines.list)
      if (line.product && line.product.images)
        line.product.images = filterExistingImages(line.product.images);

  return lines;
}
