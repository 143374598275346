import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../StepLoadingIndicator';
import { WarningAlert } from 'components/primitives/alerts';
import ShippingMethodsList from './ShippingMethodsList';
import { useSanaTexts, RichText } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { useCultureName } from 'utils/hooks';
import { getFormatNumber } from 'utils/format';
import { DangerAlert } from 'components/primitives/alerts';

const ShippingMethodStepBody = ({
  isQuote,
  totalGrossWeight,
  weightUnits,
  shippingMethods,
  selectedMethodId,
  selectedLocationId,
  locations,
  onSelect,
  onSubmit,
  validationMsg,
}) => {
  const anyMethods = shippingMethods.length > 0;
  const [methodsText] = useSanaTexts([
    anyMethods ? 'ChooseOneOfTheDeliveryMethodsListedBelow' : 'ShippingMethods_NoMethodsAvailable',
  ]).texts;

  const culture = useCultureName();

  if (!anyMethods)
    return (
      <>
        <WarningAlert>{makeRichText(methodsText)}</WarningAlert>
        <LoadingIndicator />
      </>
    );

  const numberFormat = getFormatNumber(culture);

  return (
    <>
      {validationMsg &&
        <DangerAlert role="alert" className={styles.validation}>
          {makeRichText(validationMsg)}
        </DangerAlert>
      }
      {methodsText &&
        <div className={styles.description}>
          {makeRichText(methodsText)}
        </div>
      }
      <ShippingMethodsList
        shippingMethods={shippingMethods}
        locations={locations}
        onSelect={onSelect}
        onSubmit={onSubmit}
        selectedMethodId={selectedMethodId}
        selectedLocationId={selectedLocationId}
      />
      {totalGrossWeight && shippingMethods.some(method => method.dependsOnWeight) &&
        <div className={styles.totalGrossWeight}>
          <RichText textKey={`ShippingMethods_TotalShippingWeight${isQuote ? '_Quote' : ''}`} />
          {' '}
          <span>
            {numberFormat(totalGrossWeight)}{' '}{weightUnits}
          </span>
        </div>
      }
      <LoadingIndicator />
    </>
  );
};

ShippingMethodStepBody.propTypes = {
  isQuote: PropTypes.bool,
  totalGrossWeight: PropTypes.number,
  weightUnits: PropTypes.oneOf(['KG', 'G', 'OZ', 'LB']),
  shippingMethods: PropTypes.arrayOf(PropTypes.shape({
    weightRequired: PropTypes.bool,
  }).isRequired),
  selectedMethodId: PropTypes.string,
  selectedLocationId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  validationMsg: PropTypes.node,
};

const mapStateToProps = ({ settings: { product: { weightUnits } } }) => ({
  weightUnits,
});

export default connect(mapStateToProps)(ShippingMethodStepBody);
