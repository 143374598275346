import styles from './PriceContact.module.scss';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';

const PriceSent = ({ hide }) => (
    <>
      <div className={styles.modalContent}>
        <h2 className={styles.header}><SimpleText textKey="ContactForPricing_SentRequest" /></h2>
        <div className={styles.buttonsCnt}>
          <SanaButton textKey="Ok" onClick={hide} />
        </div>
      </div>
    </>
);

PriceSent.propTypes = {
    hide: PropTypes.func,
};

export default PriceSent;