import { CREDIT_INFO_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [CREDIT_INFO_RECEIVED]: onCreditInfoReceived,
});

function onCreditInfoReceived(state, action) {
  const {
    creditDetails,
  } = action.payload;

  return {
    ...state,
    creditDetails,
  };
}