import { switchMap, map, mergeMap, takeUntil, pluck, tap, startWith } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { pipe, of } from 'rxjs';
import { retryWithToast } from 'behavior/errorHandling';
import { parse, format } from 'url';
import { OfflineModeSupport } from 'behavior/app';
import { ofType } from 'redux-observable';
import { PageComponentNames } from './componentNames';
import { createUrl } from 'behavior/routing';
import { parseQuery } from 'utils/url';

export const createApiCallEpic = (loadActionName, loadQuery, mapResponseToAction) => {
  return (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    return action$.pipe(
      ofType(loadActionName),
      switchMap(action => api.graphApi(loadQuery, action.payload).pipe(
        mergeMap(response => of(
          mapResponseToAction(response),
          unsetLoadingIndicator(),
        )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
        startWith(setLoadingIndicator()),
      )),
    );
  };
};

export const addPageParamToUrl = (url, page) => {
  const urlObj = parse(url, true);
  const query = urlObj.query;
  delete query.page;

  if (page !== 1) // Don't show paging in URL for the first page.
    query.page = page;

  urlObj.search = undefined; // Query object is used to create URL only in case when search field is absent.
  return format(urlObj);
};

export const initPageContentBlocks = fieldName => {
  return tap(data => {
    if (!data)
      return;

    const { page } = data;
    if (page && fieldName in page) {
      const content = page[fieldName];
      page[fieldName] = parseContent(content);
    }
  });
};

export const parseContent = content => {
  if (!content)
    return content;

  return content.map(
    row => ({
      ...row,
      columns: row.columns.map(column => ({
        ...column,
        contentBlocks: column.contentBlocks.map(parseContentBlock),
      })),
    }),
  );
};

const parseContentBlock = contentBlock => {
  if (!contentBlock)
    return contentBlock;

  if (contentBlock.model)
    contentBlock.model = JSON.parse(contentBlock.model);

  return contentBlock;
};

export function pluckAsPage(propName, component) {
  return pipe(
    pluck('pages', propName),
    initComponent(component),
  );
}

export function initComponent(component) {
  return map(page => {
    if (!page)
      return null;

    page.component = component;

    return { page };
  });
}

export function offlineModeSupported(offlineModeSupport) {
  return offlineModeSupport !== OfflineModeSupport.Disabled;
}

export function getBackTo(state$, ignoredRouteNames = [], language = null) {
  const state = state$.value;
  const { routeData, location } = state.routing;
  if (!location || !routeData || (language && routeData.params && routeData.params.language !== language))
    return undefined;

  if (!ignoredRouteNames.includes(routeData.routeName))
    return { url: createUrl(location), routeData };

  return state.page.backTo;
}

export function getBackToFromUrl(scope/*:Scope*/) {
  if (scope !== 'CLIENT')
    return;

  const { search, hash } = window.location;
  if (!search)
    return;

  const query = parseQuery(search);
  const backurl = query.backurl;

  if (!backurl)
    return;

  return { url: backurl + hash };
}

export function createNotFoundPageResult(page) {
  if (!page)
    return null;

  return {
    ...page,
    component: PageComponentNames.NotFound,
  };
}

export function createOfflinePage() {
  return {
    component: PageComponentNames.Offline,
    index: false,
  };
}
