exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PriceContact_modal .PriceContact_header{text-align:center;margin-top:0}.PriceContact_modal .PriceContact_top-text{margin-bottom:20px}.PriceContact_modal .PriceContact_product-cnt{display:flex;justify-content:center;align-items:center}.PriceContact_modal .PriceContact_product-cnt .PriceContact_product-left{text-align:right;padding-right:20px;margin-bottom:10px;width:100%}.PriceContact_modal .PriceContact_product-cnt .PriceContact_product-left .PriceContact_title{margin-top:0;margin-bottom:0}.PriceContact_modal .PriceContact_product-cnt .PriceContact_product-right{padding-left:20px;margin-bottom:10px;width:100%}.PriceContact_modal .PriceContact_product-cnt .PriceContact_product-right .PriceContact_title{margin-top:0;margin-bottom:0}.PriceContact_modal .PriceContact_buttons-cnt{display:flex;flex-wrap:wrap;justify-content:center;margin-top:20px;margin-bottom:-5px}.PriceContact_modal .PriceContact_buttons-cnt button{margin-left:5px;margin-right:5px;margin-bottom:10px}@media not all and (min-width:var(--breakpoints_Large,1280)px){html:not(.print) .PLP_list:not(.PLP_grid-mode) .PriceContact_contact-for-pricing-btn{margin-left:auto;margin-top:-34px;align-self:flex-start}html:not(.print) .page-LastViewedProducts .PLP_list:not(.PLP_grid-mode) .PriceContact_contact-for-pricing-btn{margin-top:0}}@media not all and (min-width:var(--breakpoints_Small,600)px){html:not(.print) .PLP_list:not(.PLP_grid-mode) .PriceContact_contact-for-pricing-btn{margin-left:0;margin-top:0;margin-right:auto}}@media (min-width:var(--breakpoints_Small,600)px),print{.PriceContact_modal .PriceContact_modal-content{padding-left:20px;padding-right:20px}}", ""]);

// exports
exports.locals = {
	"modal": "PriceContact_modal",
	"header": "PriceContact_header",
	"top-text": "PriceContact_top-text",
	"topText": "PriceContact_top-text",
	"product-cnt": "PriceContact_product-cnt",
	"productCnt": "PriceContact_product-cnt",
	"product-left": "PriceContact_product-left",
	"productLeft": "PriceContact_product-left",
	"title": "PriceContact_title",
	"product-right": "PriceContact_product-right",
	"productRight": "PriceContact_product-right",
	"buttons-cnt": "PriceContact_buttons-cnt",
	"buttonsCnt": "PriceContact_buttons-cnt",
	"contact-for-pricing-btn": "PriceContact_contact-for-pricing-btn",
	"contactForPricingBtn": "PriceContact_contact-for-pricing-btn",
	"modal-content": "PriceContact_modal-content",
	"modalContent": "PriceContact_modal-content"
};