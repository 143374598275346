import styles from '../ClosedStore.module.scss';
import { useSimpleTexts } from 'components/sanaText';
import { usePageTitle } from 'components/objects/hooks';
import LoginForm from 'components/objects/login/LoginForm';
import { RichText } from 'components/sanaText';
import { useVerticalOverflowFix } from 'utils/hooks';
import ClosedStoreLayout from '../ClosedStoreLayout';
import { connect } from 'react-redux';
import SingleSignOnButton from 'components/objects/login/SingleSignOnButton';
import PropTypes from 'prop-types';

const Login = ({ singleSignOnEnabled }) => {
  const [title] = useSimpleTexts(['Login_Title']).texts;
  const pageTitle = usePageTitle(title);

  useVerticalOverflowFix();

  return (
    <ClosedStoreLayout pageTitle={pageTitle}>
      <div className={styles.headerText}>
        <RichText textKey={singleSignOnEnabled ? 'ClosedStore_SingleSignOnLogin_Header' : 'ClosedStore_Login_Header'} />
      </div>
      <div className={styles.descriptionText}>
        <RichText textKey={singleSignOnEnabled ? 'ClosedStore_SingleSignOnLogin_Description' : 'ClosedStore_Login_Description'} />
      </div>
      {singleSignOnEnabled ? <SingleSignOnButton /> : <LoginForm />}
    </ClosedStoreLayout>
  );
};

Login.propTypes = {
  singleSignOnEnabled: PropTypes.bool,
};

export default connect(
  ({ settings: { profile } }) => ({ singleSignOnEnabled: profile && profile.singleSignOn?.isEnabled }),
)(Login);
