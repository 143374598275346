import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Thumbnail from '../Thumbnail';
import Description from '../Description';
import { ProductPrice } from 'components/primitives/product';
import OrderBox from './OrderBox';
import { AbilityTo } from 'behavior/user/constants';
import ProductComparisonSelector from '../ProductComparisonSelector';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ContactForPricing } from 'components/objects/product/priceContact';
import { ProductReplacementAlert } from 'components/primitives/alerts';
import { isAltItemUsed } from 'utils/product/alternativeItemUtils';

const ProductLine = ({ product, gridMode, showThumbnail, abilities, index }) => {
  const { xs } = useResponsiveBreakpoints();
  const gridOrSmallScreen = gridMode || xs;
  const showContactButton = product.hasPriceOnRequest;
  const usesAlternativeItem = isAltItemUsed(product.useAltItemId);

  return (
    <ProductContext.Provider value={product}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          {(showThumbnail || gridMode) && <Thumbnail gridMode={gridMode} />}
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description showStock={!showContactButton && !usesAlternativeItem} />
              <div className={styles.productAction}>
                {product.price != null && !showContactButton && !usesAlternativeItem && (
                  <div className={styles.prices}>
                    <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                  </div>
                )}
                {usesAlternativeItem && <ProductReplacementAlert altItemId={product.altItemId} altItemUrl={product.altItemUrl} /> }
                {gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
                {abilities[AbilityTo.OrderProducts] && (!showContactButton || usesAlternativeItem ? (
                  <OrderBox
                    product={product}
                    className={styles.orderBox}
                    abilities={abilities}
                    gridMode={gridMode}
                  />
                ) : (
                  <ContactForPricing product={product} />
                ))}
              </div>
            </div>
            {!gridOrSmallScreen && <ProductComparisonSelector className={styles.productComparisonSelector} />}
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductLine.propTypes = {
  product: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  abilities: PropTypes.shape({
    [AbilityTo.OrderProducts]: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ProductLine;