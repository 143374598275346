import styles from './StyleWrapper.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ContentHorizontalAlignment } from './constants';
import { NoBorderStyleValue } from '../containers/constants';
import { joinClasses } from 'utils/helpers';

const StyleWrapper = ({
  children,
  border,
  spacing,
  minHeight,
  horizontalAlignment,
  contentOrientation,
  stretchHeight,
  stretchWidth,
}) => {
  const showBorder = border.style !== NoBorderStyleValue && !!border.width;
  const { xs, sm, md } = useResponsiveBreakpoints();
  const hasOverflow = useMemo(() => border.radius && border.radius.split(/\s+/).every(val => val !== '0px'), [border.radius]);
  const actualMinHeight = useMemo(() => {
    if (xs)
      return minHeight.mobile || minHeight.tablet || minHeight.desktop || null;
    if (sm || md)
      return minHeight.tablet || minHeight.desktop || null;

    return minHeight.desktop || null;
  }, [xs, sm, md, minHeight]);

  return (
    <div
      className={joinClasses(
        styles.wrapper,
        stretchHeight && (contentOrientation === 'VERTICAL' ? styles.flexStretchHeight : styles.stretchHeight),
        stretchWidth && (contentOrientation === 'HORIZONTAL' ? styles.flexStretchWidth : styles.stretchWidth),
        hasOverflow && styles.overflow,
      )}
      style={{
        borderWidth: showBorder && border.width || 0,
        borderStyle: border.style,
        borderColor: border.color,
        borderRadius: border.radius,
        padding: spacing.padding || null,
        margin: spacing.margin || null,
        minHeight: actualMinHeight,
        textAlign: ContentHorizontalAlignment[horizontalAlignment] || null,
      }}
    >{children}</div>
  );
};

StyleWrapper.propTypes = {
  children: PropTypes.any,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  contentOrientation: PropTypes.string,
  stretchHeight: PropTypes.bool,
  stretchWidth: PropTypes.bool,
};

export default StyleWrapper;
