import { alertPropTypes } from './Alert';
import DangerAlert from './DangerAlert'
import PropTypes from 'prop-types';
import { SimpleText, RichText } from 'components/sanaText';

const ProductReplacementAlert = (
  {
    altItemId,
    altItemUrl,
    className = '',
    role = '',
    ...props
  }) => {
      const formatWith = [
        <a href={altItemUrl}>{altItemId}</a>,
      ];

      return (
        <DangerAlert className={className} role='alert' {...props}>
          {altItemId ? <RichText textKey="Product_ReplacedProduct" formatWith={formatWith} /> :
            <SimpleText textKey="Product_ReplacedProduct_Empty" />
          }
        </DangerAlert>
      )
  };

ProductReplacementAlert.propTypes = {
    ...alertPropTypes,
    altItemId: PropTypes.string,
    altItemUrl: PropTypes.string,
};

export default ProductReplacementAlert;