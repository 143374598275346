import styles from './ProductTiles.module.scss';
import { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import OrderBox from 'components/objects/productList/b2c/OrderBox';
import { ProductPrice } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';
import { ProductThumbnail } from 'components/primitives/product';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { ContactForPricing } from '../product/priceContact';
import { ProductReplacementAlert } from 'components/primitives/alerts';
import { isAltItemUsed } from 'utils/product/alternativeItemUtils';

const ProductTile = ({ product, noImage, itemsPerSlide }) => {
  const ref = useRef(null);
  const { id, url, title, image, externalItemNumber } = product;
  const getRouteData = routesBuilder.forProduct.bind(null, id);

  const showContactButton = product.hasPriceOnRequest;

  useEffect(() => {
    if (!itemsPerSlide)
      return;

    ref.current.style.width = 100 / itemsPerSlide + '%';

    return () => ref.current.style.width = '';
  }, [itemsPerSlide]);

  const { trackingSource } = useContext(ProductTrackingContext);

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;
  const usesAlternativeItem = isAltItemUsed(product.useAltItemId);

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource }}>
      <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
        <div className={styles.tileTop}>
          <div className={styles.productImg}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} draggable="false" url={url} to={getRouteData}>
                  <ProductThumbnail draggable="false" src={image && image.small} noImageSrc={noImage} title={title} />
                </Link>
              )}
            </WithProductClickTracking>
          </div>
          <div className={styles.productDescription}>
            <WithProductClickTracking>
              {handleClick => (
                <Link onClick={handleClick} url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
                  {title}
                </Link>
              )}
            </WithProductClickTracking>
            <div className={styles.productId}>
              <span className={styles.name}>
                <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
              </span>
              <span className={styles.value}>{id}</span>
            </div>
            {externalItemNumber && (
              <div>
                <SimpleText textKey="ExternalItemNumber" formatWith={[externalItemNumber]} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.tileBottom}>
          {!showContactButton || usesAlternativeItem
            ? (
              <>
                {!usesAlternativeItem && (
                  <div className={styles.productAction}>
                    <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                  </div>
                )}
                {usesAlternativeItem && <ProductReplacementAlert altItemId={product.altItemId} altItemUrl={product.altItemUrl} /> }
                <OrderBox product={product} className={styles.orderBox} withoutBackTo />
              </>
            ) : <ContactForPricing product={product} />}
        </div>
      </article>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
    externalItemNumber: PropTypes.string,
  }).isRequired,
  noImage: PropTypes.string,
  itemsPerSlide: PropTypes.number,
};

export default ProductTile;