import styles from './LoginInfo.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Icon from './Icon';
import { accordionId } from './MobileLoginInfo';

const MobileLoginInfoOpener = ({ expanded, setExpanded }) => {
  const isImpersonating = useSelector(s => s.user.isImpersonating);

  return (
    <button
      className={`${styles.openerBtn}${expanded ? ` ${styles.active}` : ''}`}
      onClick={() => setExpanded(!expanded)}
      aria-controls={accordionId}
    >
      <Icon isImpersonating={isImpersonating} />
      <FontAwesomeIcon className={styles.caret} icon={faCaretDown} rotation={expanded ? 180 : null} />
    </button>
  );
};

MobileLoginInfoOpener.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileLoginInfoOpener);
