import styles from './OrderTemplates.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText, RichText } from 'components/sanaText';
import { SanaButton } from 'components/primitives/buttons';
import { toasts } from 'behavior/toasts';
import { addToBasket, removeTemplates, UpdateType } from 'behavior/pages/orderTemplates';
import useConfirmation from 'components/objects/confirmation';
import { useSanaButton } from 'components/primitives/buttons';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const Actions = ({ selection, status, addToBasket, removeTemplates, readOnlyTemplatesSelected }) => {
  const [isAdding, setAdding] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();

  useEffect(() => clearConfirmation, []);

  useEffect(() => {
    if (status == null)
      return;

    let textKey;
    let formatArgs = null;
    if (status.type === UpdateType.Added) {
      setAdding(false);
      if (status.countAdded) {
        textKey = 'OrderTemplate_SuccessfullyCopied';
        formatArgs = [<span className={styles.countAdded}>{status.countAdded}</span>];
      }
    } else if (status.type === UpdateType.Removed) {
      setRemoving(false);
      textKey = 'OrderTemplate_SuccessfullyDeleted';
    }

    if (!textKey)
      return;

    toasts.success(<SimpleText textKey={textKey} formatWith={formatArgs} formatAsHtml disableInsiteEditor />);
  }, [status]);

  const removeTemplatesWithConfirmation = useHandlerLockerInPreview(() => {
    if (!validateSelectionForRemove(selection, readOnlyTemplatesSelected))
      return;

    const remove = () => ((removeTemplates([...selection]), setRemoving(true)));
    showConfirmation(
      remove,
      'DeleteConfirmation',
      <RichText textKey="OrderTemplates_DeleteMessage" />,
      { okText: 'ButtonText_Delete' },
      undefined,
      styles.removeTemplatePopup,
    );
  });

  const addTemplatesToBasket = useHandlerLockerInPreview(() => {
    if (!validateSelection(selection))
      return;

    addToBasket([...selection]);
    setAdding(true);
  });

  const { text: removeText, loaded: removeTextsLoaded } = useSanaButton('Remove');

  return (
    <div className={`panel-footer ${styles.templatesAction}`}>
      {removeTextsLoaded &&
        <span className={styles.remove}>
          <button
            className={`${linkStyles.link} ${isRemoving ? btnStyles.loading : ''}`}
            onClick={removeTemplatesWithConfirmation}
            disabled={isAdding}
          >
            {removeText}
          </button>
        </span>
      }
      <SanaButton
        className={`${btnStyles.btnSmall} ${btnStyles.btnAction} ${styles.addButton} ${isAdding ? btnStyles.loading : ''}`}
        textKey="AddToBasket"
        onClick={addTemplatesToBasket}
        disabled={isRemoving}
      />
      {confirmationModal}
    </div>
  );
};

Actions.propTypes = {
  selection: PropTypes.instanceOf(Set),
  status: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  addToBasket: PropTypes.func.isRequired,
  removeTemplates: PropTypes.func.isRequired,
  readOnlyTemplatesSelected: PropTypes.bool,
};

export default connect((
  { orderTemplates: { status } }) => ({ status }),
  { addToBasket, removeTemplates },
)(Actions);

function validateSelection(selection) {
  if (!selection.size)
    toasts.info(<SimpleText textKey="OrderTemplatesOverview_TemplateError" />);

  return selection.size;
}

function validateSelectionForRemove(selection, readOnlyTemplatesSelected) {
  const isAnySelected = validateSelection(selection);
  if (isAnySelected && readOnlyTemplatesSelected)
    toasts.warning(<SimpleText textKey="OrderTemplatesOverview_ReadOnlyTempalteSelected" />);

  return isAnySelected && !readOnlyTemplatesSelected;
}