import styles from './LoginInfo.module.scss';
import { memo, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteName } from 'routes';
import { LOGIN_FORM_NAME } from 'components/objects/login';
import { REGISTRATION_FORM_NAME } from 'components/objects/registration';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import LoginInfoItems from './LoginInfoItems';

const slidingDuration = 200;

export const accordionId = 'mobileLoginInfo';

const MobileLoginInfo = ({ expanded, setExpanded }) => {
  const { routeName, isLoading } = useSelector(s => ({
    isLoading: s.isLoading,
    routeName: s.routing.routeData?.routeName,
  }), shallowEqual);

  useEffect(() => {
    // Perform close on Login Form submit to avoid visual appearance of changed user data.
    if (routeName !== RouteName.Login && routeName !== RouteName.Registration)
      return;

    const setClosed = () => setExpanded(false);
    const form = document.forms[LOGIN_FORM_NAME] || document.forms[REGISTRATION_FORM_NAME];
    form.addEventListener('submit', setClosed);

    return () => form.removeEventListener('submit', setClosed);
  }, [routeName]);

  useEffect(() => {
    if (!isLoading)
      return;

    setExpanded(false);
  }, [isLoading]);

  const handleInfoItemsClick = useCallback(({ target: { tagName } }) => {
    if (tagName !== 'A' && tagName !== 'BUTTON')
      return;

    setExpanded(false);
  }, [setExpanded]);

  return (
    <VerticalSliding
      id={accordionId}
      duration={slidingDuration}
      expanded={expanded}
      containerClass={styles.mobileContainer}
      onClick={handleInfoItemsClick}
    >
      <LoginInfoItems logoutDelay={slidingDuration} />
    </VerticalSliding>
  );
};

MobileLoginInfo.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileLoginInfo);
