import { of, concat, throwError, from } from 'rxjs';
import { concatMap, catchError, mergeMap, startWith, withLatestFrom, pluck } from 'rxjs/operators';
import { basketUpdated, reloadBasketPage } from '../actions';
import { QUICK_ORDER_ADD_PRODUCTS, quickOrderLinesReceived } from '../actions.quickOrder';
import { quickOrderAddProductsMutation } from '../queries.quickOrder';
import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Updaters } from '../constants';
import { ofType } from 'redux-observable';
import { basketChangeStarted, basketChangeCompleted } from 'behavior/events';
import { trackAddToBasket, trackRemoveFromBasket } from 'behavior/analytics';
import { getModifiedProductsTrackingData } from '../util';

export default (action$, state$, { api, console }) => action$.pipe(
  ofType(QUICK_ORDER_ADD_PRODUCTS),
  pluck('payload'),
  withLatestFrom(state$),
  concatMap(([{ added, modified, size, calculated }, state]) => {
    const variables = {
      lines: added,
      size,
      hasModifiedLines: modified && !!modified.length,
      requestModifiedLines: state.analytics && state.analytics.isTrackingEnabled,
      updateInput: { modified },
      calculated,
    };

    return api.graphApi(quickOrderAddProductsMutation, variables).pipe(
      mergeMap(({ basket }) => {
        const updatedBasket = basket.addProducts.basket;
        const actions = [
          unsetLoadingIndicator(),
          basketUpdated(Updaters.QuickOrder, +new Date(updatedBasket.modifiedDate)),
          quickOrderLinesReceived(updatedBasket.productLines),
          basketChangeCompleted(added.length),
        ];

        const modifiedLines = basket.update && basket.update.modifiedLines && basket.update.modifiedLines.list;
        const addedLines = basket.addProducts.modifiedLines && basket.addProducts.modifiedLines.list;
        const { addedProducts, removedProducts } = getModifiedProductsTrackingData(state.basket.model.productLines.list, modifiedLines, addedLines);

        if (addedProducts && addedProducts.length) {
          actions.push(trackAddToBasket(addedProducts));
        }

        if (removedProducts && removedProducts.length) {
          actions.push(trackRemoveFromBasket(removedProducts));
        }

        if (variables.calculated) {
          actions.push(reloadBasketPage());
        }

        return from(actions);
      }),
      catchError(
        e => concat(of(unsetLoadingIndicator(), basketChangeCompleted(0)), throwError(e)),
      ),
      retryWithToast(action$, console),
      startWith(setLoadingIndicator(), basketChangeStarted()),
    );
  }),
);