import styles from './LoginInfo.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { connect } from 'react-redux';
import LogoutButton from './LogoutButton';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { routesBuilder } from 'routes';

const LoginInfoCustomer = ({ name, logoutDelay, isAgentAccountWithRelatedCustomers }) => {
  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      {isAgentAccountWithRelatedCustomers && (
        <Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}>
          <SimpleText textKey="ButtonText_RepresentCustomer" />
        </Link>
      )}
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

LoginInfoCustomer.propTypes = {
  name: PropTypes.string.isRequired,
  logoutDelay: PropTypes.number,
  isAgentAccount: PropTypes.bool,
  hasRelatedCustomer: PropTypes.bool,
};

export default connect(({ user }) => ({ 
  name: user.name,
  isAgentAccountWithRelatedCustomers: user.customer.isAgentAccountWithRelatedCustomers,
}))(LoginInfoCustomer);