import styles from '../MainNav.module.scss';
import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import NavDimensionsProvider from '../NavDimensionsProvider';
import SublistPositionProvider from '../SublistPositionProvider';
import { useMultiColumnDropdownSelector, useFocusResetOnLocationChange, useIsMainNavItemsLoaded } from '../hooks';
import { hoveredListClass } from '../eventHandlers';

const mutationOptions = { attributes: true, attributeFilter: ['class'] };

const DesktopNav = ({ setDesktopNavHoverStatus }) => {
  const navItemsLoaded = useIsMainNavItemsLoaded();
  const navRef = useRef(null);
  const containerRef = useRef(null);
  const multiColumnDropdown = useMultiColumnDropdownSelector();

  useFocusResetOnLocationChange();

  useEffect(() => {
    if (!navItemsLoaded)
      return;

    const [navListRootElement] = navRef.current.children;
    // Sets desktop menu hover status in Header component for handling sticky header appearance
    const navListRootObserver = new MutationObserver(mutations => {
      const [classAttrMutation] = mutations;
      const { target } = classAttrMutation;
      setDesktopNavHoverStatus(target.classList.contains(hoveredListClass));
    });

    navListRootObserver.observe(navListRootElement, mutationOptions);

    return () => {
      navListRootObserver.disconnect();
      setDesktopNavHoverStatus(false);
    };
  }, [navItemsLoaded]);

  const nav = (
    <MainNav
      ref={navRef}
      NavItemComponent={NavItem}
      navClass={`${styles.desktop} ${multiColumnDropdown ? styles.multicolumn : styles.simple}`}
    />
  );
  return (
    <Container ref={containerRef}>
      {multiColumnDropdown
        ? nav
        : (
          <NavDimensionsProvider containerRef={containerRef} navRef={navRef}>
            <SublistPositionProvider>
              {nav}
            </SublistPositionProvider>
          </NavDimensionsProvider>
        )
      }
    </Container>
  );
};

DesktopNav.propTypes = {
  setDesktopNavHoverStatus: PropTypes.func,
};

export default memo(DesktopNav);
