import styles from './PriceContact.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { SanaButton } from 'components/primitives/buttons';
import { LazyImage } from 'components/primitives/responsiveImages';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { requestPrice } from 'behavior/pages/product';
import { connect } from 'react-redux';

const PriceRequestFeedback = ({ product, noImage, isLoading, hide, setShowFeedback }) => {
    const dispatch = useDispatch();

    const { id, image, title } = product;
    const imageSrc = image && image.small;
    const { texts: [productNoImageText] } = useSanaTexts(['Product_NoImage'], makeSimpleText);

    const imgTitle = imageSrc ? title : productNoImageText;

    const clickHandler = useCallback(() => {
        setShowFeedback(true);
        dispatch(requestPrice(id, title));
    }, [id, title]);

    return (
        <>
          <div className={styles.modalContent}>
            <h2 className={styles.header}><SimpleText textKey="ContactForPricing" /></h2>
            <div className={styles.topText}><SimpleText textKey="ContactForPricing_Description" /></div>
            <div className={styles.productCnt}>
              <div className={styles.productLeft}>
                <h4 className={styles.title}><SimpleText textKey="ContactForPricing_Product" /></h4>
              </div>
              <div className={styles.productCenter}><LazyImage src={imageSrc || noImage} title={imgTitle} alt={imgTitle} /></div>
              <div className={styles.productRight}>
                <h4 className={styles.title}>{title}</h4>
                <span>
                  <span>
                      <SimpleText textKey="General_Product_Id" />
                  </span>
                  <span> {id}</span>
                </span>
              </div>
            </div>
            <div className={styles.buttonsCnt}>
              <SanaButton className={btnStyles.btnAction} textKey="PriceRequest" onClick={clickHandler} disabled={isLoading} />
              <SanaButton textKey="Cancel" onClick={hide} disabled={isLoading} />
            </div>
          </div>
        </>
    );
};

PriceRequestFeedback.propTypes = {
    product: PropTypes.object.isRequired,
    noImage: PropTypes.string,
    isLoading: PropTypes.bool,
    hide: PropTypes.func.isRequired,
    setShowFeedback: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(PriceRequestFeedback);

function mapStateToProps(state) {
    const {
        settings: { product: productSettings },
    } = state;

    if (!productSettings)
        return { noImage: null };

    return {
        noImage: productSettings.noImage.small,
    };
}