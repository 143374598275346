import styles from './EditProfile.module.scss';
import { SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';
import { FormattedPrice } from 'components/primitives/price';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { requestCreditInfo } from 'behavior/pages/editProfile';
import Spinner from 'components/primitives/spinner/Spinner';

const CreditDetails = ({ currencyInfo, creditDetails }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (creditDetails)
      return;

    dispatch(requestCreditInfo());
  }, [creditDetails]);

  return (
    <div>
      <div className={styles.heading}>
        <h1><SimpleText textKey="CompanyDetails_CreditDetails" /></h1>
        {!creditDetails && <Spinner className={styles.spinner} />}
      </div>
      {creditDetails && <div>
        <table className={styles.creditDetails}>
          <tbody>
            <tr>
              <th>
                <SimpleText textKey="CompanyDetails_CreditLimit" />
              </th>
              <td>
                <FormattedPrice price={creditDetails.creditLimit} currencyInfo={currencyInfo} />
              </td>
            </tr>
            <tr>
              <th>
                <SimpleText textKey="CompanyDetails_Balance" />
              </th>
              <td>
                <FormattedPrice price={creditDetails.balance} currencyInfo={currencyInfo} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>}
    </div>
  );
};

CreditDetails.propTypes = {
  creditDetails: PropTypes.shape({
    balance: PropTypes.number,
    creditLimit: PropTypes.number,
  }),
  currencyInfo: PropTypes.shape({
    id: PropTypes.string,
    cultureName: PropTypes.string,
  }).isRequired,
};

export default CreditDetails;