export const USER_EDIT_PROFILE = 'USER/EDIT_PROFILE';
export const editProfile = customerData => ({
  type: USER_EDIT_PROFILE,
  payload: { customerData },
});

export const CREDIT_INFO_REQUESTED = 'CREDIT_INFO_REQUESTED';
export const requestCreditInfo = () => ({
  type: CREDIT_INFO_REQUESTED,
});

export const CREDIT_INFO_RECEIVED = 'CREDIT_INFO_RECEIVED';
export const creditInfoReceived = creditDetails => ({
  type: CREDIT_INFO_RECEIVED,
  payload: { creditDetails },
});