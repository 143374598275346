import styles from '../Checkout.module.scss';
import { useRef, useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CheckoutContext from './CheckoutContext';
import scrollIntoView from 'scroll-into-view';
import { selectShippingMethod } from 'behavior/pages/checkout';
import StepButtons from './StepButtons';
import { ShippingMethodStep as ShippingMethodStepBase, ShippingMethodStepBody } from '../base/shipping';
import { useOnChange } from 'utils/hooks';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const ShippingMethodStep = ({ info, asLink, isCompleted, isActive }) => {
  const { nextStep } = useContext(CheckoutContext);
  const [showValidation, setShowValidation] = useState();

  const dispatch = useDispatch();

  const selectionRef = useRef();

  useOnChange(() => {
    selectionRef.current = {
      id: info.shippingMethodId,
      locationId: info.pickupLocation && info.pickupLocation.id,
    };

    setShowValidation(false);
  }, [info.shippingMethodId, info.pickupLocation, info.shippingMethods, isActive]);

  const disabled = !info.shippingMethods.length;

  const handleSubmit = useMemo(() => disabled ? null : (e => {
    e.preventDefault();

    if (selectionRef.current.id) {
      nextStep();
      dispatch(selectShippingMethod(selectionRef.current.id, selectionRef.current.locationId));
    } else {
      setShowValidation(true);
      setTimeout(() => scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 }), 10);
    }
  }), [disabled || nextStep]);
  const handleSelect = useCallback((id, locationId) => void (selectionRef.current.id = id, selectionRef.current.locationId = locationId), []);

  const [validationMsg] = useSanaTexts(['ShippingMethods_ValidationMessage'], makeSimpleText).texts;

  return (
    <ShippingMethodStepBase
      className={styles.shippingMethod}
      asLink={asLink}
      isQuote={info.isQuote}
      isPromotion={!!info.quote}
      isCompleted={isCompleted}
    >
      {isActive &&
        <>
          <ShippingMethodStepBody
            shippingMethods={info.shippingMethods}
            selectedMethodId={info.shippingMethodId}
            selectedLocationId={info.pickupLocation?.id}
            locations={info.pickupLocations}
            onSelect={handleSelect}
            onSubmit={handleSubmit}
            validationMsg={showValidation && validationMsg}
            isQuote={info.isQuote}
            totalGrossWeight={info.totalGrossWeight}
          />
          <StepButtons onStepSubmit={handleSubmit} disableNext={disabled} />
        </>
      }
    </ShippingMethodStepBase>
  );
};

ShippingMethodStep.propTypes = {
  info: PropTypes.shape({
    shippingMethods: PropTypes.array.isRequired,
    shippingMethodId: PropTypes.string,
    isQuote: PropTypes.bool,
    quote: PropTypes.object,
  }).isRequired,
  asLink: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default ShippingMethodStep;
