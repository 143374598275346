import styles from '../../Order.module.scss';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleLine from './SimpleLine';
import ServiceLine from './ServiceLine';
import ExtendedText from './ExtendedText';
import ProductHeaderRow from './ProductHeaderRow';
import CommentLine from './CommentLine';
import ReturnReasonLine from './ReturnReasonLine';

const ProductLine = ({
  line,
  options,
  cancelled,
  lineTexts,
  agreementId,
}) => {
  const {
    productId,
    title,
    serviceLines,
    extendedTexts,
    sublines,
  } = line;

  const hasSublines = sublines && !!sublines.length;
  const allSublinesCancelled = hasSublines && !sublines.some(line => !line.isCancelled);

  const { columnsCount } = options;

  const extendedTextsElement = <>
    {!!extendedTexts && extendedTexts.map(({ title }, index) => (
      <ExtendedText key={index} title={title} columnsAmount={columnsCount} />
    ))}
  </>;

  return (<>
    {!hasSublines && (
      <SimpleLine
        line={line}
        showId
        options={options}
        cancelled={cancelled}
        lineTexts={lineTexts}
        className={styles.productLine}
        agreementId={agreementId}
      />
    )}
    {hasSublines && (
      <>
        <ProductHeaderRow
          id={productId}
          title={title}
          columnsAmount={columnsCount}
          cancelled={cancelled || allSublinesCancelled}
          lineTexts={lineTexts}
        />
        {extendedTextsElement}
        {sublines.map((line, index) => (
          <Fragment key={index}>
            <SimpleLine
              key={index}
              line={line}
              options={options}
              className={`${styles.innerLine} ${styles.variantLine}`}
              cancelled={cancelled || line.isCancelled}
              lineTexts={lineTexts}
              agreementId={agreementId}
            />
            <CommentLine comment={line.comment} columnsAmount={columnsCount} />
            <ReturnReasonLine returnReason={line.returnReason} columnsAmount={columnsCount} />
            {line.serviceLines && line.serviceLines.map((serviceLine, index) => (
              <ServiceLine
                key={index}
                line={serviceLine}
                options={options}
                className={styles.innerLine}
              />
            ))}
          </Fragment>
        ))}
      </>
    )}
    {!hasSublines &&
      <>
        {extendedTextsElement}
        <CommentLine comment={line.comment} columnsAmount={columnsCount} />
        <ReturnReasonLine returnReason={line.returnReason} columnsAmount={columnsCount} />
      </>
    }
    {!!serviceLines && serviceLines.map((serviceLine, index) => (
      <ServiceLine key={index} line={serviceLine} options={options} />
    ))}
  </>
  );
};

ProductLine.propTypes = {
  line: PropTypes.shape({
    productId: PropTypes.string,
    title: PropTypes.string,
    serviceLines: PropTypes.array,
    extendedTexts: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
    sublines: PropTypes.arrayOf(PropTypes.shape({
      serviceLines: PropTypes.array,
      comment: PropTypes.string,
      returnReason: PropTypes.object,
    })),
    comment: PropTypes.string,
    returnReason: PropTypes.object,
    externalItemNumber: PropTypes.string,
  }).isRequired,
  options: PropTypes.shape({
    columnsCount: PropTypes.number.isRequired,
  }).isRequired,
  canceled: PropTypes.bool,
  lineText: PropTypes.object,
  agreementId: PropTypes.string,
};

export default ProductLine;
