import styles from '../Orders.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user';
import { SimpleText } from 'components/sanaText';
import { Table } from 'components/primitives/table';
import { toLocaleDate } from 'utils/format';
import ShippingTracking from '../ShippingTracking';
import { FormattedPrice } from 'components/primitives/price';
import ViewDetailsLink from '../ViewDetailsLink';
import { DocumentType } from 'behavior/documents';
import PayInvoiceCheckbox from './PayInvoiceCheckbox';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const InvoicesList = ({
  documents,
  showPrices,
  canPayInvoices,
  isB2CCustomer,
  culture,
}) => {
  const isPreview = useIsPreview();

  return (
    <Table className={styles.ordersList}>
      <thead>
        <tr>
          <th id="headerId" className={styles.documentIdCell}><SimpleText textKey="DocumentNo" /></th>
          <th id="headerOrderId" className={styles.documentIdCell}><SimpleText textKey="OrderNumber" /></th>
          <th id="headerDate" className={styles.dateCell}><SimpleText textKey="OrderDate" /></th>
          <th id="headerAddress" className={styles.billToCell}>
            <SimpleText textKey={isB2CCustomer ? 'OrderDetail_ShipToName' : 'OrderDetail_BillToName'} />
          </th>
          <th id="headerOrderEmail"><SimpleText textKey="OrderDetail_OrderEmail" /></th>
          {showPrices && <th id="headerTotal" className={styles.priceCell}><SimpleText textKey="Total" /></th>}
          {showPrices && <th id="headerOutstandingAmount" className={styles.priceCell}><SimpleText textKey="Orders_OutstTotalHeader" /></th>}
          {isB2CCustomer && <th id="headerTracking" className={styles.trackingCell}><SimpleText textKey="ShippingTracking" /></th>}
          {canPayInvoices && <th className={styles.payCell}><SimpleText textKey="PayColumnTitle" /></th>}
          <th className={styles.viewMoreCell} />
        </tr>
      </thead>
      <tbody>
        {!isPreview
          ? documents.map((invoice, index) => {
            const {
              id,
              documentId,
              orderId,
              orderDate,
              currency,
              url,
              billingAddress,
              shopAccountEmail,
              shipping: { tracking, address: shippingAddress },
              totals: { totalPrice, outstandingAmount },
            } = invoice;

            const address = isB2CCustomer
              ? shippingAddress
                ? shippingAddress.name
                : null
              : billingAddress
                ? billingAddress.name
                : null;

            return (
              <tr key={index}>
                <th scope="row" aria-labelledby="headerId">{documentId}</th>
                <td aria-labelledby="headerOrderId">{orderId}</td>
                <td aria-labelledby="headerDate">{toLocaleDate(orderDate, culture)}</td>
                <td aria-labelledby="headerAddress">{address}</td>
                <td aria-labelledby="headerOrderEmail">{shopAccountEmail}</td>
                {showPrices &&
                  <td aria-labelledby="headerTotal" className={styles.total}>
                    <FormattedPrice price={totalPrice} currencyInfo={currency} />
                  </td>
                }
                {showPrices &&
                  <td aria-labelledby="headerOutstandingAmount" className={styles.outstandingAmount}>
                    <FormattedPrice price={outstandingAmount} currencyInfo={currency} />
                  </td>
                }
                {isB2CCustomer &&
                  <td aria-labelledby="headerTracking" className={styles.trackingCell}><ShippingTracking tracking={tracking} /></td>
                }
                {canPayInvoices && <td className={styles.payCell}><PayInvoiceCheckbox invoice={invoice} /></td>}
                <td><ViewDetailsLink url={url} id={id} orderId={orderId} documentType={DocumentType.Invoice} /></td>
              </tr>
            );
          })
          : Array.from(Array(5)).map((_, index) => (
            <tr key={index}>
              <th scope="row"><Placeholder /></th>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              {showPrices && <td className={styles.total}><Placeholder /></td>}
              {showPrices && <td className={styles.outstandingAmount}><Placeholder /></td>}
              {isB2CCustomer && <td className={styles.trackingCell}><Placeholder /></td>}
              {canPayInvoices && <td className={styles.payCell}><Placeholder /></td>}
              <td><Placeholder /></td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

InvoicesList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    orderDate: PropTypes.string.isRequired,
    currency: PropTypes.object,
    url: PropTypes.string,
    billingAddress: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    shopAccountEmail: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      tracking: PropTypes.object,
      address: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
    totals: PropTypes.shape({
      totalPrice: PropTypes.number,
      outstandingAmount: PropTypes.number,
    }).isRequired,
  })),
  showPrices: PropTypes.bool.isRequired,
  canPayInvoices: PropTypes.bool.isRequired,
  isB2CCustomer: PropTypes.bool.isRequired,
  culture: PropTypes.string,
};

const mapStateToProps = ({
  user: { customerType },
  localization: { currentLanguage },
}) => ({
  isB2CCustomer: customerType === CustomerTypes.B2C,
  culture: currentLanguage && currentLanguage.cultureName,
});

export default connect(mapStateToProps)(InvoicesList);
