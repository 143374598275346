import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { SanaLinkButton } from 'components/primitives/links';

const ViewProductButton = ({ url, className }) => {
  return (
    <>
      {url && <SanaLinkButton
        textKey="ViewProduct"
        url={url}
        className={joinClasses(
          btnStyles.btn,
          btnStyles.btnSmall,
          btnStyles.btnQuote,
          'btn-quote-in-alert',
          className,
        )}
      />}
    </>
  );
};

ViewProductButton.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default memo(ViewProductButton);