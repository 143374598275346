import PropTypes from 'prop-types';
import Form from './Form';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import CreditDetails from './CreditDetails';

const Page = ({ customer, currencyInfo, templateFields, creditDetails }) => {
  if (!customer)
    return (
      <div className="msg-block">
        <SimpleText textKey="CompanyDetails_NotImpersonated_Message" />
      </div>
    );

  return (
    <>
    {templateFields && <Form templateFields={templateFields} customerId={customer.id} />}
    <CreditDetails currencyInfo={currencyInfo} creditDetails={creditDetails} />
    </>
  );
};

Page.propTypes = {
  templateFields: PropTypes.array,
  customer: PropTypes.shape({
    id: PropTypes.string,
  }),
  creditDetails: PropTypes.shape({
    balance: PropTypes.number,
    creditLimit: PropTypes.number,
  }),
  currencyInfo: PropTypes.shape({
    id: PropTypes.string,
    cultureName: PropTypes.string,
  }).isRequired,
};

Page.selectPropsFromPage = ({ templateFields, creditDetails }) => ({ templateFields, creditDetails });

export default connect(({ user, settings }) => ({
  customer: user.customer,
  currencyInfo: settings.currency,
}))(Page);