import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DangerAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';
import { AuthenticationResult } from 'behavior/user';
import { useMemo } from 'react';

const LoginFailedMessage = ({ authenticationResult }) => {
  if (!authenticationResult || authenticationResult === AuthenticationResult.Succsess)
    return null;

  const sanaTextKeys = useMemo(() => ({
    [AuthenticationResult.AccessDenied]: 'Login_AccessDenied',
    [AuthenticationResult.InternalError]: 'Login_InternalError',
  }), [authenticationResult]);

  return (
    <DangerAlert role="alert" scrollOnAppear>
      <RichText textKey={sanaTextKeys[authenticationResult]} />
    </DangerAlert>
  );
};

LoginFailedMessage.propTypes = {
  authenticationResult: PropTypes.string,
};

export default connect(({ user: { authenticationResult } }) => ({ authenticationResult }))(LoginFailedMessage);
