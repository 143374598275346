import styles from './Header.module.scss';
import { memo, useState, useCallback } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import { MobileLoginInfo, MobileLoginInfoOpener } from 'components/objects/loginInfo';
import { Logo } from 'components/primitives/logo';
import { Search } from 'components/objects/search';
import MobileTopMenuOpener from './MobileTopMenuOpener';
import MobileTopMenu from './MobileTopMenu';
import { BasketSummary } from 'components/objects/basket';
import ShoppingCartSuggestions from './ShoppingCartSuggestions';
import { HEADER_SEARCH_BOX_ID } from './constants';
import { isBrowser } from 'utils/detections';

const SEARCH_BOX_ID = HEADER_SEARCH_BOX_ID + '_mobile';

const MobileHeaderTemplate = () => {
  const [expanded, setExpanded] = useState({});

  const setLoginInfoExpanded = useCallback(expanded =>
    setExpanded(prev => prev.loginInfo === expanded ? prev : { loginInfo: expanded }), []);
  const setMenuExpanded = useCallback(expanded =>
    setExpanded(prev => prev.menu === expanded ? prev : { menu: expanded }), []);

  const { menu: menuExpanded, loginInfo: loginInfoExpanded } = expanded;

  return (
    <>
      <Container fluid className={`${styles.mobileTopContainer} ${styles.noGutters}`}>
        <Row crossAxisAlign="center" noGutters>
          <Col xs="auto" className={styles.noGutters}>
            <MobileTopMenuOpener expanded={menuExpanded} setExpanded={setMenuExpanded} />
          </Col>
          <Col className={`${styles.column} ${styles.logoWrapper}`}>
            <Logo className={styles.logo} small />
          </Col>
          <Col xs="auto" className={styles.noGutters}>
            <MobileLoginInfoOpener expanded={loginInfoExpanded} setExpanded={setLoginInfoExpanded} />
          </Col>
          <Col xs="auto">
            <BasketSummary compact hideLabel />
          </Col>
        </Row>
      </Container>
      <Container fluid className={`${styles.mobileMiddleContainer} ${styles.noGutters}`}>
        <MobileLoginInfo expanded={loginInfoExpanded} setExpanded={setLoginInfoExpanded} />
        <Search className={styles.searchBox} id={SEARCH_BOX_ID} />
        <ShoppingCartSuggestions />
      </Container>
      <Container
        fluid
        suppressHydrationWarning
        className={`${styles.topMenuContainer} ${styles.noGutters}`}
      >
        {isBrowser && <MobileTopMenu expanded={menuExpanded} setExpanded={setMenuExpanded} />}
      </Container>
    </>
  );
};

export default memo(MobileHeaderTemplate);
