import type { SingleSignOnProvider } from './types';
import { PublicClientApplication } from '@azure/msal-browser';

export const createClientApp = (provider: SingleSignOnProvider) => {
    const clientConfig = buildClientConfig(provider);
    return new PublicClientApplication(clientConfig);
};

const buildClientConfig = ({ clientId, redirectUri, authority, knownAuthorities }: SingleSignOnProvider) => {
    return {
        auth: {
            clientId,
            authority,
            redirectUri,
            knownAuthorities,
        },
        cache: {
            // Local storage to login if multiple tabs are opened. Default is session storage.
            cacheLocation: 'localStorage',
        },
    };
};