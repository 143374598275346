import styles from './PriceContact.module.scss';
import { SanaButton } from 'components/primitives/buttons';
import { Modal, useModal } from 'components/objects/modals';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import PriceRequest from './PriceRequest';
import PriceRequestFeedback from './PriceRequestFeedback';
import { memo, useState } from 'react';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useIsPreview } from 'components/objects/preview';
import { showActionForbiddenInPreviewToast } from 'behavior/preview';

const ContactForPricing = ({ product, isLoading }) => {
    const { opened, show, hide } = useModal();
    const [showPriceRequestFeedback, setShowPriceRequestFeedback] = useState(false);
    const dispatch = useDispatch();
    const isPreview = useIsPreview();

    const clickHandler = () => {
        if (isPreview) {
            dispatch(showActionForbiddenInPreviewToast());
            return;
        }
        show();
    };

    return (
        <>
            <SanaButton textKey="ContactForPricing" onClick={clickHandler} type="button" className={`${btnStyles.btnSmall} ${styles.contactForPricingBtn}`} />
            <Modal className={styles.modal} hide={hide} opened={opened} size="small" resetContentOnClose>
                {!showPriceRequestFeedback || isLoading
                    ? <PriceRequest product={product} hide={hide} isLoading={isLoading} setShowFeedback={setShowPriceRequestFeedback} />
                    : <PriceRequestFeedback hide={hide} />
                }
            </Modal>
        </>
    );
};

ContactForPricing.propTypes = {
    product: PropTypes.object.isRequired,
    sentPrice: PropTypes.bool,
};

export default connect(mapStateToProps)(memo(ContactForPricing));

function mapStateToProps(state) {
    const { isLoading } = state;

    return { isLoading };
}