exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NonOrderable_closable{overflow:hidden;margin:20px 0}.NonOrderable_closable .NonOrderable_block{margin:0}.NonOrderable_closable.NonOrderable_closed{max-height:0;transition:max-height .25s}.NonOrderable_closable:not(:last-child)>*{margin-bottom:0}.NonOrderable_lines{padding:7px 1.2em;margin:0}.NonOrderable_lines .NonOrderable_has-button{margin-bottom:12px}.NonOrderable_lines .NonOrderable_has-button .NonOrderable_line-item-wrp{display:flex}.NonOrderable_lines .NonOrderable_has-button .NonOrderable_line-item-wrp .NonOrderable_description{flex-grow:1;padding-right:10px}.NonOrderable_lines .NonOrderable_has-button .NonOrderable_line-item-wrp .btn-quote-in-alert{flex-shrink:0;margin-top:-8px;align-self:flex-start}.NonOrderable_lines .NonOrderable_has-button:last-child{margin-bottom:0}@media not all and (min-width:var(--breakpoints_Medium,960)px){html:not(.print) .NonOrderable_lines .NonOrderable_has-button{margin-bottom:0}html:not(.print) .NonOrderable_lines .NonOrderable_has-button .NonOrderable_line-item-wrp{display:block}html:not(.print) .NonOrderable_lines .NonOrderable_has-button .NonOrderable_line-item-wrp .btn-quote-in-alert{margin-top:5px}}", ""]);

// exports
exports.locals = {
	"closeTransition": "250",
	"closable": "NonOrderable_closable",
	"block": "NonOrderable_block",
	"closed": "NonOrderable_closed",
	"lines": "NonOrderable_lines",
	"has-button": "NonOrderable_has-button",
	"hasButton": "NonOrderable_has-button",
	"line-item-wrp": "NonOrderable_line-item-wrp",
	"lineItemWrp": "NonOrderable_line-item-wrp",
	"description": "NonOrderable_description"
};