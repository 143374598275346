/* eslint-disable react/no-multi-comp */
import styles from '../Checkout.module.scss';
import { Fragment, useRef, useEffect } from 'react';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { LazyImage } from 'components/primitives/responsiveImages';
import { makeSimpleText } from 'utils/render';
import { connect } from 'react-redux';
import { getFormatNumber, getFormatPercentage } from 'utils/format';
import { UomTitle, useCanViewUom } from 'components/primitives/product';
import { FormattedPrice } from 'components/primitives/price';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { requestLines } from 'behavior/pages/checkout';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { overviewLinesPropTypes } from '../propTypes';
import OverviewLinesFooter from './OverviewLinesFooter';
import { iEquals } from 'utils/helpers';

const OverviewLines = ({
  productLines,
  serviceLines,
  linesLoaded,
  showFooter,
  collapseState,
  noImageUrl,
  culture,
  currency,
  showImages,
  dispatch,
}) => {
  const [collapsed, setCollapsed] = collapseState;

  const [noImageText, agreementLine] = useSanaTexts(['Product_NoImage', 'AgreementLine'], makeSimpleText).texts;
  const formatNumber = getFormatNumber(culture);
  const formatPercentage = getFormatPercentage(culture, 2);
  const showPrices = useHasAbilities(AbilityTo.ViewPrices)[0];
  const canViewUom = useCanViewUom();

  const slidingTimeoutRef = useRef();
  useEffect(() => () => clearTimeout(slidingTimeoutRef.current), []);

  const onShowMoreClick = () => {
    toggleOverflowAnchorState(true);
    slidingTimeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);

    if (linesLoaded) {
      setCollapsed(!collapsed);
      return;
    }

    setCollapsed(false);
    dispatch(requestLines());
  };

  const quantityClassName = `${styles.quantity}${canViewUom ? '' : ' text-right'}`;

  const showLines = !!productLines.list && !collapsed;

  return (
    <div>
      <table className={styles.lines}>
        <thead>
          <tr>
            {/* Colspan attribute shouldn't be present if there are no descendent cells to stretch current cell to. */}
            <th colSpan={showImages && showLines ? 2 : null} className={styles.product}><SimpleText textKey="General_Product_Id_Compactview" /></th>
            <th colSpan={canViewUom && showLines ? 2 : null} className={styles.quantity}><SimpleText textKey="Quantity" /></th>
            {showPrices && <th className={styles.total}><SimpleText textKey="Total" /></th>}
          </tr>
        </thead>
        {showLines && productLines.list.map((line, index) => {
          let imageUrl;
          if (line.product && line.product.image)
            imageUrl = line.product.image.small;

          const labelId = 'OverviewLines' + index + 'Lbl';

          if (line.subLines && line.subLines.length) {
            const multipleLines = line.subLines.length > 1 || line.subLines[0].extendedTexts.length || line.subLines[0].serviceLines.length;
            return (
              <tbody key={index} className={styles.withSubLines} aria-labelledby={multipleLines ? labelId : null}>
                <tr className={styles.topLine}>
                  {showImages && <td aria-hidden className={styles.thumbnailCell} />}
                  {renderProductTitle(line.product.title, line.product.id, (showPrices ? 4 : 3) - !canViewUom, labelId)}
                </tr>
                {line.subLines.map((subLine, index) => {
                  const subLineImage = line.product.images.find(i => iEquals(i.variantId, subLine.variationId));

                  const subLineImageUrl = subLineImage && subLineImage.small || imageUrl;
                  const agreementTerm = subLine.salesAgreementLineId && `${agreementLine}: ${subLine.salesAgreementLineId}`;

                  return (
                    <Fragment key={index}>
                      <tr className={styles.nestedLine}>
                        {showImages &&
                          <td aria-hidden className={styles.thumbnailCell}>
                            <LazyImage
                              src={subLineImageUrl || noImageUrl}
                              title={subLineImageUrl ? subLine.title : noImageText}
                              alt={subLine.title}
                              wrapperClassName={styles.thumbnail}
                              aria-hidden
                            />
                          </td>
                        }
                        <th className={`${styles.product} ${styles.variantTitle}`} scope="row">
                          {subLine.title}
                          {agreementTerm && <span className={styles.agreementLineId}>{agreementTerm}</span>}
                        </th>
                        <td className={quantityClassName}>{formatNumber(subLine.quantity)}</td>
                        {canViewUom &&
                          <td className={styles.uom}>
                            {subLine.uom && <UomTitle id={subLine.uom.id} description={subLine.uom.description} />}
                          </td>
                        }
                        {showPrices && renderPriceNode(subLine, currency, formatPercentage)}
                      </tr>
                      {renderExtendedTexts(subLine.extendedTexts, showPrices, showImages, canViewUom)}
                      {renderServiceLines(subLine.serviceLines, showPrices, showImages, canViewUom, currency, formatNumber, styles.nestedLine)}
                    </Fragment>
                  );
                })}
              </tbody>
            );
          }

          const title = line.title || line.product.title;
          const asGrouping = line.extendedTexts && line.extendedTexts.length || line.serviceLines.length;
          const agreementTerm = line.salesAgreementLineId && `${agreementLine}: ${line.salesAgreementLineId}`;

          return (
            <tbody key={index} aria-labelledby={asGrouping ? labelId : null}>
              <tr className={styles.topLine}>
                {showImages &&
                  <td aria-hidden className={styles.thumbnailCell}>
                    <LazyImage
                      src={imageUrl || noImageUrl}
                      title={imageUrl ? title : noImageText}
                      alt={title}
                      wrapperClassName={styles.thumbnail}
                      aria-hidden
                    />
                  </td>
                }
                {renderProductTitle(title, line.product.id, undefined, labelId, agreementTerm, line.product.externalItemNumber)}
                <td className={quantityClassName}>{formatNumber(line.quantity)}</td>
                {canViewUom &&
                  <td className={styles.uom}>
                    {line.uom && <UomTitle id={line.uom.id} description={line.uom.description} />}
                  </td>
                }
                {showPrices && renderPriceNode(line, currency, formatPercentage)}
              </tr>
              {renderExtendedTexts(line.extendedTexts, showPrices, showImages, canViewUom)}
              {renderServiceLines(line.serviceLines, showPrices, showImages, canViewUom, currency, formatNumber, styles.nestedLine)}
            </tbody>
          );
        })}
        {!!serviceLines.length && !collapsed && (
          <tbody>
            {renderServiceLines(serviceLines, showPrices, showImages, canViewUom, currency, formatNumber, styles.nestedLine)}
          </tbody>
        )}

        {showFooter &&
          <tfoot>
            <tr>
              <td colSpan={showLines ? 5 - !showPrices - !showImages - !canViewUom : 3}>
                <OverviewLinesFooter
                  totalCount={productLines.totalCount}
                  collapsed={collapsed}
                  onShowMoreClick={onShowMoreClick}
                />
              </td>
            </tr>
          </tfoot>
        }
      </table>
    </div>
  );
};

OverviewLines.propTypes = overviewLinesPropTypes;

export default connect(({ settings: { product, currency, basket }, localization }) => ({
  noImageUrl: product ? product.noImage.small : null,
  culture: localization.currentLanguage.cultureName,
  showImages: basket.showImages,
  currency,
}))(OverviewLines);

function renderProductTitle(title, id, colSpan = null, elementId = null, agreementTerm = null, externalItemNumber = null) {
  return (
    <th scope="row" colSpan={colSpan} className={styles.product}>
      <span className={styles.title} id={elementId}>{title}</span>
      <span className={styles.id}>{id}</span>
      {externalItemNumber && (
        <SimpleText textKey="ExternalItemNumber" formatWith={[externalItemNumber]} />
      )}
      {agreementTerm && <span className={styles.agreementLineId}>{agreementTerm}</span>}
    </th>
  );
}

function renderPriceNode(line, currency, formatPercentage) {
  return (
    <td className={`${styles.total} ${line.discount > 0 ? styles.hasDiscount : ''}`}>
      <FormattedPrice price={line.subTotal} currencyInfo={currency} />
      {line.discount > 0 &&
        <div className={styles.discountWrapper}>
          <span className={styles.discount}>{formatPercentage(line.discount, true)}</span>
          <SimpleText textKey="Discount" />
        </div>
      }
    </td>
  );
}

function renderServiceLines(serviceLines, showPrices, showImages, showUom, currency, formatNumber, className = '') {
  if (!serviceLines)
    return null;

  return serviceLines.map((serviceLine, index) => (
    <tr key={index} className={`${styles.serviceLine} ${className}`}>
      {showImages && <td aria-hidden />}
      {renderProductTitle(serviceLine.title, serviceLine.id)}
      <td className={`${styles.quantity}${showUom ? '' : ' text-right'}`}>{formatNumber(serviceLine.quantity)}</td>
      {showUom && <td aria-hidden />}
      {showPrices &&
        <td className={styles.total}>
          <FormattedPrice price={serviceLine.price} currencyInfo={currency} />
        </td>
      }
    </tr>
  ));
}

function renderExtendedTexts(extendedTexts, pricesAreShown, imagesAreShown, uomsAreShown) {
  if (!extendedTexts)
    return null;

  return extendedTexts.map((text, index) => (
    <tr key={index} className={`${styles.nestedLine} ${styles.extendedText}`}>
      {imagesAreShown && <td role="presentation" aria-hidden />}
      <td colSpan={(pricesAreShown ? 4 : 3) - !uomsAreShown}>{text.title}</td>
    </tr>
  ));
}