import { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelector';
import { EVENT_SOURCES } from 'behavior/analytics';
import { useOnCustomerChanged } from 'utils/hooks';
import { quickOrderAddProducts, clearQuickOrder } from 'behavior/basket';
import { useUpdateContext } from '../updateContext';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { isAltItemUsed } from 'utils/product/alternativeItemUtils';

const QuickOrder = () => {
  const texts = useQuickOrderTexts();
  const dispatch = useDispatch();
  const { quickOrderMaxLines, modifiedDate } = useSelector(({ settings, basket: { modifiedDate } }) => ({
    quickOrderMaxLines: settings.basket && settings.basket.quickOrderMaxLines,
    modifiedDate,
  }), shallowEqual);
  const { getModifiedLines, reset: resetUpdateContext } = useUpdateContext();
  const [productAddDisabled, setProductAddDisabled] = useState(false);

  const onProductAdd = useCallback((line, { useAltItemId }) => {
    if (productAddDisabled)
      return;

    const isReplacedProduct = isAltItemUsed(useAltItemId);
    dispatch(quickOrderAddProducts([line], getModifiedLines(false), quickOrderMaxLines, isReplacedProduct));
    resetUpdateContext(!isReplacedProduct);
    setProductAddDisabled(true);
  }, [productAddDisabled, quickOrderMaxLines]);

  useOnCustomerChanged(() => {
    dispatch(clearQuickOrder());
  });

  useEffect(() => () => {
    dispatch(clearQuickOrder());
  }, []);

  useEffect(() => void (setProductAddDisabled(false)), [modifiedDate]);

  return (
    <ProductSelector
      texts={texts}
      onProductAdd={useHandlerLockerInPreview(onProductAdd)}
      productAddDisabled={productAddDisabled}
      productClickTrackingSource={EVENT_SOURCES.quickOrder}
    />
  );
};

export default memo(QuickOrder);

function useQuickOrderTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'ButtonText_AddToBasket',
    'Loading',
    'QuickOrder_ProductNotFound',
    'ProductCannotBeOrderedAtThisTime',
  ]).texts;

  return useMemo(() => {
    const [
      searchBoxPlaceholder,
      addBtn,
      loadingText,
      productNotFoundText,
      productCannotBeOrderedMessage,
    ] = texts;

    return {
      searchBoxPlaceholder: makeSimpleText(searchBoxPlaceholder),
      addBtn: makeSimpleText(addBtn),
      loadingText: makeSimpleText(loadingText),
      productNotFoundText: makeRichText(productNotFoundText),
      productCannotBeOrderedMessage: makeRichText(productCannotBeOrderedMessage),
    };
  }, [texts]);
}